<template>
  <div>
    <!-- 卡片视图 -->
    <el-card>
      <el-row :gutter="12">
        <el-col :span="3">
            <el-date-picker type="date" placeholder="选择起始日期" v-model="queryInfo.startTime" @change="getDataReportByGoods()" :picker-options="pickerOptions"></el-date-picker>
        </el-col>
        <el-col :span="1">至</el-col>
        <el-col :span="3">
            <el-date-picker type="date" placeholder="选择截止日期" v-model="queryInfo.endTime" @change="getDataReportByGoods()" :picker-options="pickerOptions"></el-date-picker>
        </el-col>
        <el-col :span="3">
            <el-button type="primary" @click="reportExcel">导出<i class="el-icon-upload el-icon--right"></i></el-button>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
        <el-row :gutter="24">
            <el-col :span="3">
                <el-radio-group v-model="queryInfo.ticketType" @input="getDataReportByGoods()">
                    <el-radio-button label="散客"></el-radio-button>
                    <el-radio-button label="团队"></el-radio-button>
                    <el-radio-button label="合并"></el-radio-button>
                </el-radio-group>
            </el-col>
            <el-col :span="2">
                门店销售人数：{{ sellNum }}人
            </el-col>
            <el-col :span="2">
                退票人数：{{ refundNum }}人
            </el-col>
            <el-col :span="2" v-for="item in sellInTypeMoney" :key="item.支付方式">
                {{ item.支付方式 }}：{{ item.金额.toFixed(2) }}元
            </el-col>
        </el-row>

        <el-table
            :data="sellTableData"
            border
            show-summary
            :span-method="objectSpanMethod"
            :summary-method="getSummaries"
            :row-class-name="tableRowClassName"
            :header-cell-class-name="tableHeaderClassName"
            style="width: 100%">
            <el-table-column :label="`售票员销售报表（${queryInfo.ticketType}售票）`">
                <el-table-column
                prop="ticketType"
                label="票类">
                </el-table-column>
                <el-table-column
                prop="支付方式"
                label="支付方式">
                </el-table-column>
                <el-table-column
                prop="票名"
                label="票名">
                </el-table-column>
                <el-table-column
                prop="单价"
                label="单价">
                </el-table-column>
                <el-table-column
                prop="票数"
                label="人数">
                </el-table-column>
                <el-table-column
                prop="金额"
                label="金额">
                </el-table-column>
            </el-table-column>
        </el-table>

        <el-table
            :data="refundTableData"
            border
            show-summary
            :span-method="objectSpanMethod"
            :summary-method="getSummaries"
            :row-class-name="tableRowClassName"
            :header-cell-class-name="tableHeaderClassName"
            style="width: 100%">
            <el-table-column :label="`售票员销售报表（${queryInfo.ticketType}退票）`">
                <el-table-column
                prop="ticketType"
                label="票类">
                </el-table-column>
                <el-table-column
                prop="支付方式"
                label="支付方式">
                </el-table-column>
                <el-table-column
                prop="票名"
                label="票名">
                </el-table-column>
                <el-table-column
                prop="单价"
                label="单价">
                </el-table-column>
                <el-table-column
                prop="票数"
                label="人数">
                </el-table-column>
                <el-table-column
                prop="金额"
                label="金额">
                </el-table-column>
            </el-table-column>
        </el-table>
    </el-card>


  </div>
</template>

<script>
import DateFormat from "@/utils/DateFormat.js";

  export default {
    name: 'report',
    data() {
      return {
        queryInfo: {
            startTime: new DateFormat(new Date()).toString(
                "yyyy-0m-0d"
            ),
            endTime: new DateFormat(new Date()).toString(
                "yyyy-0m-0d"
            ),
            ticketType: '散客',
        },
        sellTableData: [],
        refundTableData: [],
        sellNum: 0,
        refundNum: 0,
        sellInTypeMoney: [],
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        orderTypeOptions: [],
        userName: window.sessionStorage.getItem("userName"),
      };
    },
    created() {
        this.getDataReportByGoods()
    },
    methods: {
        getDataReportByGoods() {
            let that = this
            const loading = that.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            if (this.queryInfo.startTime) {
                this.queryInfo.startTime = new DateFormat(new Date(this.queryInfo.startTime)).toString(
                    "yyyy-0m-0d"
                );
            }

            if (this.queryInfo.endTime) {
                this.queryInfo.endTime = new DateFormat(new Date(this.queryInfo.endTime)).toString(
                    "yyyy-0m-0d"
                );
            }


            that.$axios({
                url: `/manager/dataReportByGoods`,
                params: this.queryInfo,
                method: "GET",
            })
            .then((res) => {
                console.log(res);
                loading.close();
                if (res.code == 200) {
                    that.sellTableData = res.data.sellResult
                    that.refundTableData = res.data.refundResult
                    that.sellNum = res.data.sellNum
                    that.refundNum = res.data.refundNum
                    that.sellInTypeMoney = res.data.sellInTypeMoney
                } else {
                    that.sellTableData = []
                    that.refundTableData = []
                    that.sellNum = 0
                    that.refundNum = 0
                    that.sellInTypeMoney = []
                    that.$message.warning(res.msg)
                }
            })
            .catch((err) => {
                console.log(err)

                loading.close();
            });
        },

        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总计';
                    return;
                }
                if (index === 1 || index === 2 || index === 3) {
                    sums[index] = ' ';
                    return;
                }

                const values = data.filter(item => item.支付方式 == "汇总").map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] += '';
                    } else {
                        sums[index] = 'N/A';
                    }
            });

            return sums;
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                return {
                    rowspan: row.typeRowSpan,
                    colspan: row.typeColSpan,
                }
            }

            if (columnIndex === 1) {
                return {
                    rowspan: row.rowSpan,
                    colspan: row.colSpan,
                }
            }
        },
        tableRowClassName({row, rowIndex}) {
            console.log(row, rowIndex)
            if (row.票名 == '小计') {
                return 'warning-row';
            } else if (row.支付方式 == '汇总') {
                return 'success-row';
            }
            return '';
        },
        tableHeaderClassName({row, rowIndex}) {
            return 'header-row';
        },
        getOrderTypeOptions() {
            let id = '2e8ac8b3-e201-4c29-91d6-c09deb7785a9'
            let that = this
            that.$axios({
                url: `/mall/enumDetail?id=${id}&timestemp=${ new Date().getTime() }`,
                method: "GET",
            })
            .then((res) => {
                if (res.code == 200) {
                    this.orderTypeOptions = res.data
                } else {
                    that.$message.warning(res.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            });
        },
        reportExcel() {
            let that = this
            const loading = that.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            const params = {
                ticketType: that.queryInfo.ticketType,
                userName: that.userName,
                startDate: new DateFormat(new Date(this.queryInfo.startTime)).toString(
                    "yyyy-0m-0d"
                ),
                endDate: new DateFormat(new Date(this.queryInfo.endTime)).toString(
                    "yyyy-0m-0d"
                ),
                curTime: new DateFormat(new Date()).toString(
                    "yyyy-0m-0d 0h:0f:0s"
                ),
                sellResult: that.sellTableData,
                refundResult: that.refundTableData,
                sellNum: that.sellNum,
                refundNum: that.refundNum,
                sellInTypeMoney: that.sellInTypeMoney,
            }
            const name = `售票员销售日报（${params.ticketType}）_${params.startDate == params.endDate ? params.startDate : params.startDate + "至" + params.endDate}`

            that.$axios({
                url: `/manager/exportExcel`,
                data: {
                    data: JSON.stringify(params)
                },
                method: "POST",
                responseType: 'blob'
            }).then(function (data) {
                if (!data) {
                    return
                }
                let url = window.URL.createObjectURL(new Blob([data]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', name + '.xlsx')

                document.body.appendChild(link)
                link.click()

                loading.close();
                that.$message({
                    type: "success",
                    message: "正在导出报表...",
                });
            }).catch(function (err) {
                console.log(err)

                loading.close();
            });
        },
    }
  };
</script>

<style>
  .el-table .warning-row {
    background: #c1d59c;
  }

  .el-table .success-row {
    background: #6587b4;
  }

  .el-table .header-row {
    background: #5d5e5c;
    text-align: center;
  }
</style>
